import cn from '@/utils/style/cn.ts'
import type { ReactNode } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import lodash from '~/imports/lodash.ts'

type Props = (
  | {
      form: UseFormReturn<any, any, any>
      field: string
      children?: never
    }
  | {
      form?: never
      field?: never
      children: ReactNode
    }
) & {
  className?: string
}

/**
 * Simple form error component.
 */
export default function FormError({ form, field, children, className }: Props) {
  const errorMessage =
    children ??
    (lodash.get(form!.formState.errors, field!)?.message as unknown as string)

  return (
    <>
      {field && <div className="hidden" data-form-error={field} />}

      {errorMessage && (
        <div
          data-testid={field ? `form-error-${field}` : 'form-error'}
          className={cn(
            className,
            'rounded-md border border-red-500 bg-red-50 px-3 py-2 leading-relaxed text-red-600',
          )}
        >
          {errorMessage}
        </div>
      )}
    </>
  )
}
