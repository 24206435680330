'use client'

import { TypeAnimation } from 'react-type-animation'

const cycleWords = [
  'simplified',
  'modernized',
  'streamlined',
  'optimized',
  'demystified',
  'organized',
  'systematized',
  'synchronized',
  'unified',
] as const

export default function LandingPageHeroTitleCycle() {
  return (
    <div className="h-16 lg:h-32">
      <TypeAnimation
        sequence={cycleWords.flatMap((word) => [word, 1500])}
        wrapper="span"
        speed={50}
        repeat={Number.POSITIVE_INFINITY}
        className="text-marketing-blue-700"
      />
    </div>
  )
}
