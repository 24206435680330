'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import { CircleCheck } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import MarketingButton from './Button.tsx'

const portalId = '8293589'
const formId = '42ef2152-ee42-4836-82ab-d6f121598bce'
const formSubmissionURL =
  'https://api.hsforms.com/submissions' +
  `/v3/integration/submit/${portalId}/${formId}`

const learnMoreFormSchema = z.object({
  name: z.string().trim(),
  email: z.string().trim(),
  companyName: z.string().trim().optional(),
  jobTitle: z.string().trim().optional(),
  phoneNumber: z.string().trim().optional(),
  message: z.string().trim().optional(),
})

export default function MarketingLayoutLearnMoreForm() {
  const [isShowingFullForm, setIsShowingFullForm] = useState(false)

  const learnMoreForm = useForm<z.infer<typeof learnMoreFormSchema>, any, any>({
    resolver: zodResolver(learnMoreFormSchema),
  })

  const learnMoreFormData = learnMoreForm.watch()

  useEffect(() => {
    if (
      !isShowingFullForm &&
      learnMoreFormData.name &&
      learnMoreFormData.email
    ) {
      setIsShowingFullForm(true)
    }
  }, [learnMoreFormData.name, learnMoreFormData.email])

  const learnMoreMutation = useMutation({
    mutationFn: (data: z.infer<typeof learnMoreFormSchema>) => {
      return fetch(formSubmissionURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          submittedAt: `${new Date().getTime()}`,
          fields: [
            {
              objectTypeId: '0-1',
              name: 'firstname',
              value: data.name.split(' ')[0],
            },
            {
              objectTypeId: '0-1',
              name: 'lastname',
              value: data.name.split(' ').slice(1).join(' ') ?? '',
            },
            {
              objectTypeId: '0-1',
              name: 'email',
              value: data.email,
            },
            {
              objectTypeId: '0-1',
              name: 'company',
              value: data.companyName,
            },
            {
              objectTypeId: '0-1',
              name: 'jobtitle',
              value: data.jobTitle,
            },
            {
              objectTypeId: '0-1',
              name: 'phone',
              value: data.phoneNumber,
            },
            {
              objectTypeId: '0-1',
              name: 'message',
              value: data.message,
            },
            {
              objectTypeId: '0-1',
              name: 'hs_lead_status',
              value: 'NEW',
            },
          ],
          context: {
            pageUri: window.location.href,
            pageName: document.title,
          },
        }),
      })
    },
  })

  return (
    <form
      onSubmit={learnMoreForm.handleSubmit(
        (data: z.infer<typeof learnMoreFormSchema>) => {
          learnMoreMutation.mutate(data)
        },
      )}
      autoComplete="off"
      className="flex flex-col gap-10"
    >
      <div className="flex flex-col gap-5 text-base lg:flex-row">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4 md:flex-row">
            <input
              type="text"
              placeholder="Full Name"
              className="h-16 w-full flex-1 border-none text-marketing-sm lg:w-80 lg:text-marketing-lg"
              required
              {...learnMoreForm.register('name')}
            />

            <input
              type="email"
              placeholder="Work Email"
              className="h-16 w-full flex-1 border-none text-marketing-sm lg:w-80 lg:text-marketing-lg"
              required
              {...learnMoreForm.register('email')}
            />
          </div>

          <AnimatePresence>
            {isShowingFullForm && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                className="flex flex-col gap-4"
              >
                <div className="flex flex-col gap-4 lg:flex-row">
                  <input
                    type="text"
                    placeholder="Company Name"
                    className="h-16 w-full flex-1 border-none text-marketing-sm lg:w-80 lg:text-marketing-lg"
                    {...learnMoreForm.register('companyName')}
                  />

                  <input
                    type="text"
                    placeholder="Job Title"
                    className="h-16 w-full flex-1 border-none text-marketing-sm lg:w-80 lg:text-marketing-lg"
                    {...learnMoreForm.register('jobTitle')}
                  />
                </div>

                <div className="flex flex-col gap-4 md:flex-row">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="h-16 w-full flex-1 border-none text-marketing-sm lg:w-80 lg:text-marketing-lg"
                    {...learnMoreForm.register('phoneNumber')}
                  />

                  <input
                    type="text"
                    placeholder="Message (Optional)"
                    className="h-16 w-full flex-1 border-none text-marketing-sm lg:w-80 lg:text-marketing-lg"
                    {...learnMoreForm.register('message')}
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <MarketingButton
          color="black"
          type="submit"
          loading={learnMoreMutation.isPending}
          disabled={learnMoreMutation.isSuccess}
        >
          Submit
        </MarketingButton>

        {learnMoreMutation.isSuccess && (
          <p className="flex items-center gap-2">
            <CircleCheck
              absoluteStrokeWidth
              strokeWidth={1.5}
              size={20}
              className="text-green-300"
            />
            Thanks! We'll be in touch with you shortly.
          </p>
        )}
      </div>
    </form>
  )
}
