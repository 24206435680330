import FormError from '@/ui/FormError.tsx'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import MarketingButton from './Button.tsx'

const marketingCheckEmailSchema = z.object({
  email: z.string().trim().email(),
})

type MarketingCheckEmailFormProps = {
  error?: React.ReactNode
  onSubmit: (
    data: z.infer<typeof marketingCheckEmailSchema>,
  ) => void | Promise<void>
}

export default function MarketingCheckEmailForm({
  error,
  onSubmit,
}: MarketingCheckEmailFormProps) {
  const form = useForm<z.infer<typeof marketingCheckEmailSchema>>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(marketingCheckEmailSchema),
  })

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <input
            {...form.register('email')}
            className="!h-12 w-full"
            type="email"
          />
          <FormError className="mt-2" field="email" form={form} />
        </div>

        <MarketingButton color="blue" loading={form.formState.isSubmitting}>
          Submit
        </MarketingButton>
      </div>

      {!!error && <FormError>{error}</FormError>}
    </form>
  )
}
