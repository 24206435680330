import type { LoadingSpinnerProps } from '@/ui/LoadingSpinner.tsx'
import LoadingSpinner from '@/ui/LoadingSpinner.tsx'
import clsx from 'clsx'
import type { ButtonHTMLAttributes, ReactNode } from 'react'
import React from 'react'

type MarketingButtonColor = 'black' | 'blue' | 'white-transparent'

export interface MarketingButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: MarketingButtonColor
  loading?: boolean
  children: ReactNode
}

const colorMap = {
  black: 'bg-marketing-blue-990 text-white hover:brightness-75',
  blue: 'bg-marketing-blue-700 border border-marketing-blue-350 text-white hover:brightness-150',
  'white-transparent':
    'border border-marketing-gray-600 bg-transparent text-marketing-white hover:bg-marketing-white/10',
} as const satisfies Record<MarketingButtonProps['color'], string>

const spinnerColorMap = {
  blue: 'white',
  black: 'white',
  'white-transparent': 'gray',
} as const satisfies Record<
  MarketingButtonProps['color'],
  LoadingSpinnerProps['color']
>

/**
 * Simple button component of different colors, that handles loading and
 * disabled states.
 */
const MarketingButton = React.forwardRef<
  HTMLButtonElement,
  MarketingButtonProps
>(
  (
    {
      type,
      color,
      loading = false,
      disabled = false,
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      type={type}
      disabled={loading || disabled}
      className={clsx(
        colorMap[color],
        className,
        'relative inline-flex h-12 cursor-pointer items-center justify-center whitespace-nowrap rounded-md px-6 text-marketing-sm font-light transition lg:h-16 lg:px-10 lg:text-marketing-lg',
      )}
      {...props}
    >
      {children}

      {(loading || disabled) && (
        <div className="absolute inset-[-1px] flex items-center justify-center bg-white/30">
          {loading && <LoadingSpinner color={spinnerColorMap[color]} />}
        </div>
      )}
    </button>
  ),
)

export default MarketingButton
