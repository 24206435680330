'use client'

import clsx from 'clsx'
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
  useSpring,
} from 'framer-motion'
import Image from 'next/image'
import { useRef, useState } from 'react'
import DashboardPNG from './assets/Dashboard.png'
import DashboardMinPNG from './assets/DashboardMin.png'
import OrbitPNG from './assets/Orbit.png'
import OrbitMinPNG from './assets/OrbitMin.png'

export default function LandingPageLoanOps() {
  const loanOpsRef = useRef<HTMLDivElement>(null)
  const [isDarkThemeActive, setIsDarkThemeActive] = useState(true)
  const { scrollY, scrollYProgress } = useScroll({
    target: loanOpsRef,
    offset: ['start start', 'end end'],
  })

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 20,
    restDelta: 0.001,
  })

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (loanOpsRef.current === null) return

    const newIsDarkThemeActive =
      latest < (loanOpsRef.current?.getBoundingClientRect().height * 2) / 3

    if (isDarkThemeActive !== newIsDarkThemeActive) {
      setIsDarkThemeActive(newIsDarkThemeActive)
    }
  })

  return (
    <div ref={loanOpsRef} className="h-[250vh]">
      <div
        className={clsx(
          'sticky top-0 h-screen text-center transition duration-1000',
          isDarkThemeActive ? 'bg-marketing-blue-990' : 'bg-marketing-white',
        )}
      >
        <div className="flex h-screen w-full flex-col items-stretch gap-4 overflow-hidden pt-16">
          <div
            className={clsx(
              'mx-auto flex h-3 w-1/2 max-w-xl flex-col items-center rounded-full border transition',
              isDarkThemeActive
                ? 'border-marketing-gray-350'
                : 'border-marketing-gray-600',
            )}
          >
            <motion.div
              className="h-full w-full bg-marketing-blue-700"
              style={{ scaleX }}
            />
          </div>

          <div className="relative h-16 lg:h-20">
            <AnimatePresence initial={false}>
              {isDarkThemeActive ? (
                <motion.div
                  key="dark"
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0, x: -200 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -200 }}
                  className="absolute inset-0 flex items-center justify-center px-4"
                >
                  <h2 className="text-marketing-3xl text-marketing-white transition duration-1000 lg:text-marketing-4xl">
                    <span className="text-marketing-gray-450">Legacy</span> loan
                    operations
                  </h2>
                </motion.div>
              ) : (
                <motion.div
                  key="light"
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0, x: 200 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 200 }}
                  className="absolute inset-0 flex items-center justify-center px-4"
                >
                  <h2 className="text-marketing-3xl text-marketing-blue-990 transition duration-1000 lg:text-marketing-4xl">
                    Loan ops{' '}
                    <span className="text-marketing-blue-700">2.0</span>
                  </h2>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="relative h-20">
            <AnimatePresence initial={false}>
              {isDarkThemeActive ? (
                <motion.div
                  key="dark"
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0, x: -400 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -400 }}
                  className="absolute inset-0 px-4"
                >
                  <p className="mx-auto max-w-2xl text-marketing-gray-350 transition duration-1000">
                    Legacy loan operations infrastructure has relied on a
                    disjointed, siloed set of tools resulting in unnecessary
                    friction, errors, and inefficiency.
                  </p>
                </motion.div>
              ) : (
                <motion.div
                  key="light"
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0, x: 400 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 400 }}
                  className="absolute inset-0 px-4"
                >
                  <p className="mx-auto max-w-2xl text-marketing-gray-600 transition duration-1000">
                    Sirvatus is built from the ground up to be a single system
                    of truth to manage the entire loan lifecycle.
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="relative flex-grow">
            <AnimatePresence initial={false}>
              {isDarkThemeActive ? (
                <motion.div
                  key="dark"
                  transition={{ duration: 1, ease: 'easeOut' }}
                  initial={{ opacity: 0, scale: 0.6, y: 200 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.6, y: 200 }}
                  className="absolute inset-0 flex flex-col items-center"
                >
                  <Image
                    src={OrbitMinPNG}
                    priority
                    alt="Orbit"
                    className="h-full w-auto max-w-none lg:hidden"
                  />
                  <Image
                    src={OrbitPNG}
                    priority
                    alt="Orbit"
                    className="hidden h-full w-auto max-w-none lg:block"
                  />
                </motion.div>
              ) : (
                <motion.div
                  key="light"
                  transition={{ duration: 1, ease: 'easeOut' }}
                  initial={{ opacity: 0, scale: 0.6, y: 200 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.6, y: 200 }}
                  className="absolute inset-0 flex flex-col items-center"
                >
                  <Image
                    src={DashboardMinPNG}
                    priority
                    alt="Dashboard"
                    className="h-full w-auto max-w-none translate-x-[calc(50%-45vw)] shadow-xl lg:hidden"
                  />
                  <Image
                    src={DashboardPNG}
                    priority
                    alt="Dashboard"
                    className="hidden h-full w-auto max-w-none shadow-xl lg:block"
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  )
}
